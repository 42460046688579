import React, { useState, useEffect } from 'react';
import { withPrefix, graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Form } from '../components/Form';
import FormSection from '../components/Form/FormSection';
import TextHero from '../components/TextHero';
import SocialIcons, { SocialItemsData } from '../components/SocialIcons';
import SiteMeta from '../components/SiteMeta';
import Link from '../components/Link';
import { useCookie } from 'react-use';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const SubmitMessage = ({ wasSuccessful }) => {

  return (
    <div className="demo-form__submit-view">
      <div className="demo-form__message">
        <h3 className="demo-form__submit-title">
          {wasSuccessful ? "Thank you!" : "We're sorry"}
        </h3>
        <p className="demo-form__submit-subtitle">
          {wasSuccessful ? "We look forward to showing you all the ways ProEdge can revolutionize your workforce." : "Something went wrong. Refresh the page and try again or come back later."}
        </p>
        <p className="demo-form__submit-footer">
          Need help? Have a question? <Link href="https://proedge.pwc.com/contact">Contact us</Link>.
        </p>
      </div>
      <div className="demo-form__social">
        <p>Let's connect</p>
        <SocialIcons
          items={SocialItemsData.filter((item) =>
            ['twitter', 'linkedin'].includes(item.slug),
          )}
          theme="light"
        />
      </div>
    </div>
  )
}
const PrivacyNotice = styled.p`
  display: block;
  width: 100%;
  text-align: center;
  max-width: 720px;
  margin: 40px auto 0;
  font-size: 90%;
  font-style: italic;
`;
const DemoPage = ({ data }) => {

  const COOKIE_NAME = 'PWC_PROEDGE_USER_GATE';
  // const COOKIE_VALUE_GATED = 0;
  const COOKIE_VALUE_UNGATED = '1';

  const [value, updateCookie, deleteCookie] = useCookie(COOKIE_NAME);

  const {
    metaTitle,
    metaDescription: { metaDescription },
    heroTitle,
    heroBody: { heroBody },
    formSection
  } = data.contentfulRequestADemoPage;


  const TextHeroContent = {
    title: heroTitle,
    subtitle: heroBody
  }
  const RedirectURL = () => {
    updateCookie(COOKIE_VALUE_UNGATED);
    if (typeof window !== 'undefined') {
      window.location = '/demo/thank-you';
    }
    return null;
  }
  const handleFormSubmitSuccess = () => {
    updateCookie(COOKIE_VALUE_UNGATED);
  }

  return (
    <Layout>
      <Helmet>
        <title>Request demo - PwC ProEdge</title>
        <meta http-equiv="refresh" content="0; url=https://www.pwc.com/us/en/products/workforce-products-contact.html"></meta>
        <meta name="google-site-verification" content="D_NciX0TA9oOQBcI0zbG_UaHaf0CtQnGBT4LYK6qWVg" />
        <meta name="referrer" content="no-referrer-when-downgrade" />
        <meta http-equiv="content-language" content="en-us" />
        <meta property="og:image" content={withPrefix('wf-open-graph.jpg')} />
        <link rel="icon" type="image/png" href={withPrefix('icon.png')} />
      </Helmet>
      <TextHero {...TextHeroContent} />
      <FormSection>
        <Form onSubmitSuccess={() => RedirectURL()} {...formSection} />
        <PrivacyNotice>By submitting your email address, you acknowledge that you have read the <a href="https://www.pwc.com/us/en/site/privacy.html" target="_blank">Privacy Statement</a>.</PrivacyNotice>
      </FormSection>

    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulRequestADemoPage(slug: {eq: "pe-demo"}) {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroBody {
        heroBody
      }
      heroTitle
      formSection {
        ...Form
      }
    }
  }
`;

export default DemoPage;
